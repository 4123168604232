import React from "react";
import styled from "styled-components";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import {
  Section,
  BreadCrumb,
  GradientWrapper,
  SectionPageTitle,
} from "../components/Section";
import { ArrowRight, HomeIcon, ArrowLineIcon } from "../components/Icons";
import SecondaryLinkBtn from "../components/Button/SecondaryLinkButton";
import Img from "gatsby-image";
import BreakpointDown from "../components/Media/BreakpointDown";

const BlogContainer = styled.div`
  max-width: 1150px;
  width: 100%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
`;

const BlogWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
`;
const BlogLeft = styled.div`
  flex: 0 0 55%;
  max-width: 55%;
  padding-left: 15px;
  padding-right: 15px;
  ${BreakpointDown.lg`
   flex:0 0 100%;
   max-width:100%;
 `}
`;
const BlogRight = styled.div`
  flex: 0 0 45%;
  max-width: 45%;
  padding-left: 88px;
  padding-right: 15px;
  position: relative;
  ${BreakpointDown.xl`
  padding-left:15px;
 `}
  ${BreakpointDown.lg`
   flex:0 0 100%; 
   max-width:100%;
   margin-top:20px;
 `}
`;

// const BlogListCount = styled.div`
//   font-size: 20px;
//   font-family: "Poppins", sans-serif;
//   margin-bottom: 20px;
// `;
const Card = styled.div`
  display: flex;
  background: transparent;
  flex-direction: column;
  border-bottom: 1px solid #cbd1e2;
  padding-bottom: 25px;
  margin-bottom: 38px;
  ${BreakpointDown.md`
    padding-bottom:20px;
    margin-bottom:20px;
  `}
  &:last-child {
    border-bottom: 0;
    ${BreakpointDown.md`
    padding-bottom:0px;
    margin-bottom:20px;
  `}
  }
`;
const CardImg = styled.div`
  position: relative;
  max-width: 100%;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
`;

const BlogDate = styled.div`
  background: #223c7e;
  padding: 8px;
  position: absolute;
  left: 0;
  top: 30px;
  z-index: 4;
  color: #fff;
  font-size: 18px;
  max-width: 72px;
  line-height: 20px;
  text-align: center;
  padding: 4px;
  font-family: "Poppins", sans-serif;
`;
const CardBody = styled.div`
  margin-top: 30px;
  ${BreakpointDown.md`
  margin-top:15px; 
 `}
`;
const BlogInfo = styled.div`
  font-size: 16px;
  a {
    color: #121316;
    &:hover {
      color: #f36224;
    }
  }
  span {
    &:first-child {
      &:after {
        content: "";
        width: 1px;
        height: 12px;
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px;
        margin-right: 3px;
        background: #121316;
      }
    }
  }
`;
const CardDescription = styled.div`
  margin-bottom: 10px;
`;
const CardTitle = styled.div`
  a {
    color: #121316;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 24px;
    &:hover {
      color: #f36224;
    }
  }
  line-height: 38px;
  margin-bottom: 5px;
`;
const CardBtn = styled.div`
  span {
    padding-left: 0;
  }
`;

const HeadingBorderBottom = styled.h6`
  border-bottom: 1px solid #cbd1e2;
  padding-bottom: 10px;
  margin-bottom: 0;
`;
const CategoryListItemWrapper = styled.div`
  margin-top: 0px;
`;
const CategoryItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px dashed #cbd1e2;
  padding-top: 16px;
  padding-bottom: 16px;
  a {
    color: #121316;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 16px;

    svg {
      fill: #cbd1e2;
      transition: all 0.4s ease-in-out;
    }
    &:hover {
      color: #f36224;
      svg {
        fill: #f36224;
        margin-left: 4px;
      }
    }
  }
  ${BreakpointDown.md`
    padding-top:10px;
  padding-bottom:10px;
  `}
  &:last-child {
    border-bottom: 0;
  }
`;

// Recent Blog
const RecentBlog = styled.div`
  margin-top: 40px;
  ${BreakpointDown.md`
    margin-top:20px;
  `}
`;
const RecentBlogCard = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  background: #fff;
  & + & {
    margin-top: 40px;
    ${BreakpointDown.lg`
       margin-top:20px;
     `}
  }
`;
const RecentBlogImg = styled.div`
  position: relative;
  max-width: 140px;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
`;
const RecentBlogBody = styled.div`
  margin-left: 22px;
  ${BreakpointDown.lg`
   margin-left:15px; 
  `}
`;
const RecentBlogTittle = styled.div`
  a {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 0;
    color: #121316;
    letter-spacing: 0.16px;
    font-weight: 600;
    &:hover {
      color: #f36224;
    }
  }
`;
const RecentBlogDateTime = styled.div`
  font-size: 14px;
  color: #868da2;
  line-height: 24px;
`;

const StickyWrapper = styled.div`
  position: sticky;
  top: 180px;
`;
function BlogPage(queryData) {
  let pageData = queryData.data.contentfulBlogListingPage;
  let articlesData = queryData.data.allContentfulBlogArticle.nodes;
  let categoriesData = queryData.data.allContentfulBlogCategory.nodes;
  const recentArticles = [];

  for (let index = 0; index < 2; index++) {
    recentArticles.push(articlesData[index]);
  }

  return (
    <Layout pageName="blog">
      <SEO
        title={pageData.metaTitle}
        description={pageData.metaDescription.metaDescription}
      />
      <Section
        bgColor="rgba(203, 209, 226, 0.55)"
        pt="180px"
        xpt="60px"
        pb="20px"
      >
        <BreadCrumb top="75" xtop="15px">
          <div className="container">
            <Link to="/">
              <HomeIcon /> <ArrowRight />
            </Link>
            <Link className="line-after-heading">{pageData.headingText}</Link>

            <SectionPageTitle className="h2" mt="40px" mb="40px">
              <div
                dangerouslySetInnerHTML={{
                  __html: pageData.descriptionText.childMarkdownRemark.html,
                }}
              />
            </SectionPageTitle>
          </div>
        </BreadCrumb>
      </Section>
      <GradientWrapper>
        <Section
          pt="100px"
          pb="180px"
          xpt="70px"
          xpb="100px"
          mpt="30px"
          bgColor="transparent"
        >
          <BlogContainer>
            {/* <BlogListCount>Showing 1-4 of 8 Blogs</BlogListCount> */}
            <BlogWrapper>
              <BlogLeft>
                {articlesData.map((article) => {
                  return (
                    <Card>
                      <CardImg>
                        <BlogDate>{article.postDate}</BlogDate>
                        <Img
                          fluid={article.articleImage.fluid}
                          alt={article.articleImage.title}
                        />
                      </CardImg>
                      <CardBody>
                        <BlogInfo>
                          <span>Author: {article.authorName}</span>{" "}
                          {article.blogCategories.map((category) => {
                            return (
                              <span>
                                <Link to={category.caterogyUrl}>
                                  {category.categoryName}
                                </Link>
                              </span>
                            );
                          })}
                        </BlogInfo>
                        <CardTitle>
                          <Link to={article.articleUrl}>
                            {article.articleHeading}
                          </Link>
                        </CardTitle>
                        <CardDescription
                          dangerouslySetInnerHTML={{
                            __html:
                              article.shortDescription.childMarkdownRemark.html,
                          }}
                        />
                        <CardBtn>
                          <Link to={article.articleUrl}>
                            <SecondaryLinkBtn
                              text="Continue Reading"
                              icon={<ArrowLineIcon />}
                            />
                          </Link>
                        </CardBtn>
                      </CardBody>
                    </Card>
                  );
                })}
              </BlogLeft>
              <BlogRight>
                <StickyWrapper>
                  <CategoryListItemWrapper>
                    <HeadingBorderBottom>Categories</HeadingBorderBottom>
                    {categoriesData.map((category) => {
                      return (
                        <CategoryItem>
                          <Link to={category.caterogyUrl}>
                            {category.categoryName}
                            <span>
                              <ArrowLineIcon />
                            </span>
                          </Link>
                        </CategoryItem>
                      );
                    })}
                  </CategoryListItemWrapper>
                  <RecentBlog>
                    <HeadingBorderBottom>Recent Blogs</HeadingBorderBottom>
                    {recentArticles.map((recentArticle) => {
                      return (
                        <RecentBlogCard>
                          <RecentBlogImg>
                            <Img
                              fluid={recentArticle.articleImage.fluid}
                              alt={recentArticle.articleImage.title}
                            />
                          </RecentBlogImg>

                          <RecentBlogBody>
                            <RecentBlogTittle>
                              <Link to={recentArticle.articleUrl}>
                                {recentArticle.articleHeading}
                              </Link>
                            </RecentBlogTittle>
                            <RecentBlogDateTime>
                              {recentArticle.postDate}
                            </RecentBlogDateTime>
                          </RecentBlogBody>
                        </RecentBlogCard>
                      );
                    })}
                  </RecentBlog>
                </StickyWrapper>
              </BlogRight>
            </BlogWrapper>
          </BlogContainer>
        </Section>
      </GradientWrapper>
    </Layout>
  );
}

export default BlogPage;

export const query = graphql`
  query BlogListingPage {
    contentfulBlogListingPage {
      pageUrl
      metaTitle
      metaDescription {
        metaDescription
      }
      headingText
      descriptionText {
        childMarkdownRemark {
          html
        }
      }
    }
    allContentfulBlogArticle(sort: { order: DESC, fields: postDate }) {
      nodes {
        articleUrl
        articleHeading
        articleImage {
          title
          fluid {
            aspectRatio
            base64
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
        postDate(formatString: "DD MMM YYYY")
        authorName
        blogCategories {
          categoryName
          caterogyUrl
        }
        shortDescription {
          childMarkdownRemark {
            html
          }
        }
      }
    }
    allContentfulBlogCategory {
      nodes {
        categoryName
        caterogyUrl
      }
    }
  }
`;
